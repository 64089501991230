var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-gateway" }),
        _vm._v(_vm._s(_vm.$t("Gateway Firmware")) + " ")
      ]),
      _c("v-data-table", {
        staticClass: "tbl-type01 mt-10",
        attrs: {
          headers: _vm.labelHeaders,
          items: _vm.labelItems,
          page: _vm.page,
          "hide-default-footer": true,
          "item-key": "id"
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "page-count": function($event) {
            _vm.pageCount = $event
          }
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c(
              "v-dialog",
              {
                attrs: { width: "700" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              { staticClass: "btn", attrs: { text: "" } },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v(_vm._s(_vm.$t("Firmware Update")))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.dialog,
                  callback: function($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog"
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "popup add_store_popup" },
                  [
                    _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-overview" }),
                      _vm._v(_vm._s(_vm.$t("Firmware Update")) + " ")
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: { dense: "", "hide-details": "" },
                            model: {
                              value: _vm.radioGroup,
                              callback: function($$v) {
                                _vm.radioGroup = $$v
                              },
                              expression: "radioGroup"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pt-0 pb-0" },
                                  [
                                    _c("v-radio", {
                                      staticClass: "form-radio flat",
                                      attrs: { label: "Whole Model" + " *" }
                                    }),
                                    _c("v-select", {
                                      staticClass: "form-select",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        placeholder:
                                          "" + _vm.$t("Select the model"),
                                        hint:
                                          "" +
                                          _vm.$t(
                                            "Select the model for firmware update"
                                          ),
                                        "persistent-hint": "",
                                        items: ["A-001", "A-002", "A-003"],
                                        clearable: ""
                                      },
                                      on: { keydown: _vm.clearTabindex }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pt-0 pb-0" },
                                  [
                                    _c("v-radio", {
                                      staticClass: "form-radio flat",
                                      attrs: { label: "Individual Gateway *" }
                                    }),
                                    _c("v-text-field", {
                                      staticClass: "form-input",
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        solo: "",
                                        clearable: "",
                                        placeholder:
                                          "" + _vm.$t("Input the MAC ID"),
                                        hint:
                                          "" +
                                          _vm.$t(
                                            "Input gateway's MAC ID for firmware update"
                                          ),
                                        "persistent-hint": ""
                                      },
                                      on: { keydown: _vm.clearTabindex }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c("v-select", {
                          staticClass: "form-select",
                          attrs: {
                            outlined: "",
                            dense: "",
                            label: "Firmware*",
                            placeholder: "Select the firmware version",
                            hint: "Select the firmware version",
                            "persistent-hint": "",
                            items: ["A-001", "A-002", "A-003"],
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c("v-file-input", {
                          staticClass: "form-file",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            "prepend-icon": "",
                            label: _vm.$t("File Upload") + "*",
                            placeholder: _vm.$t("File Upload"),
                            "append-icon": ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          scopedSlots: _vm._u([
                            {
                              key: "append",
                              fn: function() {
                                return [
                                  _c("img", {
                                    attrs: {
                                      width: "24",
                                      height: "24",
                                      src: require("@/assets/img/ico-upload-file.gif")
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn",
                            attrs: { text: "", icon: "" },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Update")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn",
                            attrs: { text: "", icon: "" },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("Cancel")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("v-btn", { staticClass: "btn ml-2", attrs: { text: "" } }, [
              _vm._v(_vm._s(_vm.$t("Export")))
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "pageInfo" }, [
              _vm._v(
                "1 " +
                  _vm._s(_vm.$t("to")) +
                  " 7, 7 " +
                  _vm._s(_vm.$t("in total"))
              )
            ]),
            _c("v-pagination", {
              staticClass: "paging",
              attrs: { length: _vm.pageCount, color: "#2f3b4c" },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }